.p-skeleton {
  height: 100% !important;
  width: 100% !important;
}

p-skeleton {
  display: block;
  padding-bottom: 5px;
}

.skeletonTitle {
  height: 3rem;
  width: 15rem
}

.skeletonButtonArea {
  height: 5rem;
  width: 10rem
}

.skeletonMenuBar {
  height: 5rem;
  width: 100%
}

.skeletonLabel {
  height: 2rem;
  width: 5rem;
}

.skeletonInput {
  height: 3rem;
  width: 100%;
}

.skeletonTextArea {
  height: 5rem;
  width: 100%;
}

.skeletonButton {
  height: 3rem;
  width: 25rem;
}

.skeletonTableRow {
  width: 100%;
  height: 3rem;
}
