app-participant-advanced-search-modal {
  .searchRow {
    display: flex;

    p-radiobutton {
      margin-right: 5px;
    }
  }

  .p-button-link {
    .p-button-label {
      color: var(--light-secondary-text-color);
    }

    .p-button-icon {
      color: var(--light-secondary-text-color);
    }

    margin-top: 15px;
  }
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
