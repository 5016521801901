$pastel-green: #20bf55;
$pearl: #f8f8f8; // Body
$white: #fff;
$alabaster: #fafafa;
$black: #000;
$mako: #444a54;
$shuttle-grey: #59606e;
$tuna: #31353b;
$grey: #888;
$dusty-grey: #9c9c9c;
$salmon: #ffd7ca;
$paternoster: #dee2e6;
$bright-green: #22C55E;
$green-mid-faded: #66d579;
$green-light: #C8E6C9;
$bright-red: #EF4444;
$bright-orange: #EB6B0D;
$orange-mid-faded: #e7b455;
$orange-light: #FFECB3;
$teal: #4AB8B9;
$primary-blue: #3B82F6;
$sea-blue: #009CB8
