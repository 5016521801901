
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import 'theme/variables';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$trial-management-system-primary: mat.define-palette(mat.$indigo-palette);
$trial-management-system-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$trial-management-system-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$trial-management-system-theme: mat.define-light-theme((
  color: (
    primary: $trial-management-system-primary,
    accent: $trial-management-system-accent,
    warn: $trial-management-system-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($trial-management-system-theme);

/* Third Party Libs */
@import '../node_modules/primeng/resources/themes/saga-blue/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeicons/primeicons.css';
// Theme
@import 'theme/colour-pallette';
@import 'theme/light/light-theme';
@import 'theme/theme-config';
@import 'theme/general';
@import 'theme/typography';
@import 'theme/form-general';
// Prime Overrides
@import 'theme/components/button/prime-button';
@import 'theme/components/input/input';
@import "theme/components/menuItem/menuItem";
@import "theme/components/breadcrumb/breadcrumb";
@import "theme/components/tabView/tabPanel";
@import "theme/components/table/table";
@import "theme/components/skeleton/skeleton";
@import "theme/components/dropdown/dropdown";
@import 'theme/overrides/drag-drop';

// Auth
@import 'app/auth/components/login/login.component';
// Shared/General-Shared
@import 'app/shared/general-shared/components/main-header/main-header.component';
// Dashboard
@import 'app/trials-dashboard/components/trials-dashboard/trials-dashboard.component';
// Trials Management
@import 'app/trial-details/components/trial-add-edit/trial-add-edit.component';
// Trial Details
@import "app/trial-details/components/trial-details/trial-details.component";
@import "app/trial-details/components/trial-edit-modal/trial-edit-modal.component";
@import "app/trial-details/components/sms-template-add-edit/sms-template-add-edit.component";
@import "app/trial-details/components/comm-add-edit/comm-add-edit.component";
@import "app/trial-details/components/trial-steps-overview/trial-steps-overview.component";
@import "app/trial-details/components/trial-edit-modal/trial-edit-modal.component";
@import "app/trial-details/components/participant-add-modal/participant-add-modal.component";
@import "app/trial-details/components/participant-add-modal/participant-advanced-search-modal/participant-advanced-search-modal.component";
@import "app/trial-details/components/step-status-overlay/step-status-overlay.component";
@import "app/shared/general-shared/components/status-tag/status-tag.component";
@import "app/trial-details/components/info-screen-builder/info-screen-builder.component";
@import "app/trial-details/components/notification-builder-edit/notification-builder-edit.component";
@import "app/trial-details/components/notification-builder-options/notification-builder-options.component";
@import "app/trial-details/components/notification-builder-preview/notification-builder-preview.component";
@import "app/trial-details/components/image-manager/image-manager.component";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

