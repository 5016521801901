@import url('https://fonts.googleapis.com/css2?family=Inter');

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  letter-spacing: 0.01rem;
  font-kerning: none;
  margin: 0;
  color: var(--primary-text-color);
}

h1, h1 span, h1 .mat-icon {
  font-size: 50px;
  font-weight: 700;
}

p {
  margin: 0;
  color: var(--primary-text-color);
}

h2 {
  font-size: 14px;
  color: var(--primary-text-color);
}

div, p, span, a {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-text-color);
}

strong {
  font-weight: 700;
  color: var(--primary-text-color);
}

a:hover {
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

h2.modalSubHeader {
  font-family: 'Inter', 'sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
}

h2.modalSectionHeader {
  font-family: 'Inter', 'sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}
