.cdk-drag-preview, .cdk-drag-placeholder {
  background: white;
  padding: 10px;
  border: 1px dashed $create-edit-notification-builder-module-border;
  text-align: center;

  img {
    width: 100%;
  }

  hr {
    height: $notification-delimiter-height;
    background: $create-edit-notification-builder-module-border;
  }

  .title {
    font-weight: 800;
    color: #3297ce !important;
  }
}
