app-trial-steps-overview {
  .stepTableHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .stepTableActions {
    display: inline-flex;
    flex-direction: row;

    .reorderButtons {
      align-self: flex-start;
    }

    .p-element {
      margin-left: 5px;

    }
  }

  .p-dropdown.w-100 {
    width: 220px;
  }

  .stepType span {
    padding: 5px 10px;
    background-color: #3B82F6 !important;
    border-radius: 3px;
    color: white !important;
  }

  .hideInternal {
    border: 1px solid #E2E8F0;
    border-radius: 4px;
  }
}
