
button.p-button {
  //background-color: unset;
}

.p-button-label, .p-button-icon {
  color: var(--secondary-text-color);
  margin-left: 0;
}

.p-button-text {

  .p-button-label, .p-button-icon {
    color: var(--textOnly-button-color);
  }
}

.p-button-danger .p-button {
  background: var(--error-color);
  min-width: 272px;

  &:hover {
    background: var(--error-color);
  }
}

.p-button-outlined {
  .p-button-label, .p-button-icon {
    color: var(--active-menu-item-text-color)
  }
}

.p-button-primary .p-button {
  background: var(--accent-border-color);
  min-width: 272px;

  &:hover {
    background: var(--accent-color);
    border-color: var(--accent-color);
    opacity: 0.9;
  }
}

.invalid {
  background: red;
  border-color: var(--error-color) !important;
}


.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  background: var(--secondary-accent-color);
}

.p-button-link .p-button-label {
  color: var(--primary-text-color);
  font-weight: 700;
}

.p-button-link {
  padding-left: 0;
  margin-bottom: 5px;
}
