app-comm-add-edit {
  .templateDropdownItem {
    display: flex;
    flex-direction: column;
  }

  .newTemplateButton {
    border: 1px solid #E2E8F0;
    border-radius: 4px;
  }

  .commForm {
    display: flex;
    flex-direction: column;
  }

  .stepTableHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .stepTableActions {
    display: inline-flex;
    flex-direction: row;

    .p-element {
      margin-left: 5px;
    }
  }

  .p-dropdown.w-100 {
    width: 220px;
  }

  .formRowWithButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .p-field {
      float: left;
    }

    .actions {
      float: right;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .p-button {
        width: 100%;
      }
    }
  }

  .p-field > * {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  p-table {
    margin-top: 15px;
  }

  .stepTagHolder {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .tag1 > .p-tag {
    background: orange;
  }

  .tag2 > .p-tag {
    background: teal;
  }

  .tag3 > .p-tag {
    background: hotpink;
  }

  .tag4 > .p-tag {
    background: green;
  }

  .tag5 > .p-tag {
    background: purple;
  }
}
