app-sms-template-add-edit {
  .smsFormHeader, .smsBody {
    display: flex;
    flex-direction: row;
  }

  .smsFormHeader {
    justify-content: space-between;
  }

  .p-field {
    width: 50%;
    margin-top: 10px
  }

  .smsBody {
    .p-field {
      min-width: 50%;
    }

    p-table {
      padding: 10px;
    }
  }

  .p-field > .p-inputgroup {
    display: flex;
  }

  .p-field > * {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .templateButtonGroup {
    padding-top: 10px;
    p-button{
      margin-right: 10px;
    }
  }
}

