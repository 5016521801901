app-trials-dashboard {
  .trails-grid-holder {
    padding: 24px;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .newTrialBtn.p-button-primary {
    align-self: flex-end;
    padding-bottom: 10px;

    .p-button {
      background: $primary-blue;
    }
  }

  .draft > .p-tag {
    background: $bright-orange;
  }

  .ready > .p-tag {
    background: $teal;
  }

  .published > .p-tag {
    background: $bright-green;
  }

  .paused > .p-tag {
    background: $bright-red;
  }

  .archived > .p-tag {
    background: $shuttle-grey;
  }
}
