app-trial-details {
  .trial-details-holder {
    padding: 24px;
    max-width: 1500px;
    margin: 0 auto;
  }

  .p-toolbar {
    background: var(--body-background);
    padding: 0 1rem 0 0;
  }

  .p-breadcrumb {
    border: 0;
  }

  .trialDetailsActions {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .p-button {
      width: 152px;
      margin-bottom: 10px
    }
  }

  .breadcrumbBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .participantTableHeader {
    display: flex;
    flex-direction: wrap;

    .left {
      flex: 1;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }

  tr {
    cursor: pointer;
  }
}
.p-tooltip>.p-tooltip-text {
  width: fit-content !important;
  white-space: nowrap !important;
}

.p-tooltip-text {
  background-color: white !important;
  color: black !important;
  padding: 10px 20px !important;
}
