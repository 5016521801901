$image-manager-override-padding: 20px;

app-info-screen-builder {
  .error-banner {
    width: 100%;
    border-radius: 8px;
    background: rgba(#f44336, .2);
    border: 1px solid #f44336;
    padding: 8px 16px;
    color: #f44336;
    margin-top: 16px;
  }

  .create-edit-header .p-button {
    margin-right: 10px;
  }

  .full-width {
    padding: 10px 0px 10px 0px;
    width: 100%;
  }

  .form-control-holder {
    width: 100%;

    input {
      width: 100%;
    }

    .p-dropdown {
      width: 100%
    }
  }

  .warning-text {
    text-align: center;
  }

  .loading-holder {
    vertical-align: top;
    display: inline-block;
  }

  .create-edit-form {
    height: 70vh;
    overflow: scroll;
    padding-top: 5px;
  }

  .inline-dropdown .mat-mdc-form-field {
    margin: 0 20px;
  }
  .dropdown {
    width: 100%;
  }
  .dropdown .mat-mdc-form-field, .dropdown mat-error.past-error {
    margin: 0 20px 0 47px;
  }

  .form-padding {
    margin: 8px 20px 0 47px;
  }

    mat-form-field.date-field {
    position: relative;
    margin: 0 40px 0 47px !important;
    mat-icon {
      position: absolute;
      left: calc(100% + 25px);
      top: 6px;
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
      color: rgba(0,0,0,.54);
    }
  }

  .module-editor {
    h2 {
      text-transform: capitalize;
    }

    .mat-mdc-form-field {
      width: 100%;
    }

    img {
      width: auto;
      margin-bottom: 20px;
      max-height: 180px;
    }
  }

  .notification-builder {
    height: 70vh;
    overflow: scroll;

    background: $create-edit-notification-builder-background;
    vertical-align: top;
    padding: $create-edit-padding-general;

    .notification-wrapper {
      background: $create-edit-notification-background;
      padding: $create-edit-padding-general 10px;
      border-radius: $create-edit-notification-border-radius;
    }
  }

  .notification-builder, .edit-form {
    iframe {
      width: 100%;
    }
  }

  app-image-manager {
    padding: $image-manager-override-padding 0;
    max-width: 350px;
  }

  .notification-icon-editor app-image-manager {
    .chosen-img {
      max-width: 47px;
    }

    .chosen-img-holder {
      text-align: center;
    }
  }

  .email-template-input-holder {
    display: inline-block !important;
    min-width: 220px;
  }

  .email-template-info {
    margin-left: 10px;
  }

}
