app-participant-add-modal {
  .p-button-link {
    .p-button-label {
      color: var(--light-secondary-text-color);
    }

    .p-button-icon {
      color: var(--light-secondary-text-color);
    }

    margin-top: 15px;
  }

  .modalFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .searchInputs {
      display: flex;
      flex-direction: column;
      margin-left: 5px;

      button {
        margin: 0;
      }

    }
  }
}
