app-login {
  article {
    height: 100vh;
    width: 100vw;
  }

  h1 {
    margin-bottom: 16px;
    font-size: var(--login-header-font-size);
    font-weight: var(--login-header-font-weight);
  }

  h2 {
    margin-bottom: 16px;
    font-size: var(--login-subheader-font-size);
    font-weight: var(--login-subheader-font-weight);
  }

  form {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: left;
    position: absolute;
    inset: 40% 0 0 50%;
    transform: translate(-50%, -50%);
  }

  input.p-inputtext {
    width: 100%;
    height: 84px;
    align-self: stretch;
  }

  .p-message-error {
    width: 300px;
    margin-left: 3px;
  }

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px 5px 5px 5px;
  }

  .changelog {
    margin-left: auto;
  }

  .buttonGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
  }

  .p-field {
    align-self: stretch;
    flex-wrap: nowrap;
    display: flex;
    margin-bottom: 16px;
  }

  .p-float-label {
    display: inline-flex;
    flex-direction: row;
    align-items: stretch;
    align-self: stretch;
    margin-top: 10px;
    min-width: 100%;
  }

  @media screen and (max-width: 1210px) {
    .p-field {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 960px) {
    .p-menubar .p-menubar-button {
      display: none;
    }
    .p-menubar {
      position: inherit;
    }
    .p-menubar .p-menubar-root-list {
      display: flex;
      align-items: center;
      position: unset;
      padding: unset;
      background: inherit;
      border: unset;
      box-shadow: unset;
      width: unset;
    }
  }

  .p-button-secondary {
    min-height: 36px;
    min-width: 188px;
  }

  .p-button-primary {
    min-height: 52px;
  }

  .p-button-danger {
    min-height: 52px;
  }
}
