@mixin light-theme {
  --primary-text-color: #{$black};
  --secondary-text-color: #{$white};
  --panel-text-color: #{$black};
  --primary-tms: #{$dusty-grey};
  --accent-color: #{#299CB8};
  --accent-border-color: #{#299CB8};
  --input-border-color: #{#cBD5E1};
  --body-background: #{$white};
  --panel-background: #{$pearl};
  --border-color: #{$black};
  --error-color: #f44336;
  --secondary-accent-color: #4AB8B9;
  --login-header-font-size: 48px;
  --login-header-font-weight: 800;
  --login-subheader-font-size: 32px;
  --login-subheader-font-weight: 600;
  --active-menu-item-text-color: #3B82F6;
  --active-menu-item-background-color: #EFF6FF;
  --textOnly-button-color: #1E293B;
  --light-secondary-text-color: #64748B;
  --light-utility-skipped-background: #ffd23d;
  --light-utility-warning-background: #F59E0B;
  --light-utility-complete-background: #BBF7D0;
  --light-utility-failed-background: #fc9f9f;
}
