app-main-header {

  .layout-top-bar {
    height: 80px;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .header-left {
      display: flex;
      justify-content: flex-start;

      .p-menubar {
        margin-left: 20px;
      }
    }

    .logout-menu {
      justify-self: flex-end;
      justify-items: flex-end;
      align-self: flex-end;
      align-items: flex-end;
    }

    .logo-title {
      padding: 2px 8px;
      background: var(--secondary-text-color);
      color: var(--body-background);
      border-radius: 4px;
    }
  }

  .topbar-nav {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }
  }

  .headerLogo {
    height: 56px;
    width: 56px;
    margin-right: 83px;
  }
}
