app-notification-builder-edit {
  .p-dropdown {
    width: 100%;
  }
  .carousel-editing {
    .mat-mdc-form-field-error {
      margin-bottom: 0;
    }
    .carousel-slide-items {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 8px;
      min-height: 37px;

      mat-icon {
        cursor: pointer;
        color: $create-edit-notification-builder-carousel-add-icon;
        &:hover {
          color: lighten($create-edit-notification-builder-carousel-add-icon, 10);
        }
      }
    }
    .slide-item {
      background: black;
      color: white;
      min-width: map-get($carousel-editing-dots, size);
      min-height: map-get($carousel-editing-dots, size);
      border: map-get($carousel-editing-dots, border) solid $main-black;
      border-radius: 50%;
      margin-right: 4px;
      text-align: center;
      vertical-align: middle;
      line-height: 33px;
      cursor: pointer;
      &.invalid {
        background: $danger-btn-background;
        border-color: $danger-btn-background;
      }
      &.selected {
        border: map-get($carousel-editing-dots, border) solid $main-black;
        background: $main-white;
        color: $main-black;
        &.invalid {
          color: $danger-btn-background;
          background: $main-white;
          border-color: $danger-btn-background;
        }
      }
    }
    button {
      margin-bottom: 10px;
    }
  }

  .product-selection-container {
    .p-dropdown{
      width: 100%;
    }

    margin-top: 20px;
  }

  .override-product-radio-container {
    margin-bottom: 20px;
  }
}
