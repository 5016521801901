app-status-tag {
  p-tag {
    white-space: nowrap;
  }

  cursor: pointer;

  .pi {
    padding-right: 5px;
  }

  .upcoming {
    background: #3B82F6;
  }

  .inProgress {
    background: #009CB8;
  }

  .complete {
    background: #2adb56
  }

  .skipped {
    background: #f0b324
  }

  .missed {
    background: #f0d824
  }

  .failed {
    background: #f04624
  }

  .actionRequired {
    background: #fa2d3e
  }

  .active {
    background: #2adb56
  }

  .removed {
    background: #969596
  }
}
