app-trial-add-edit {
  .trial-management-holder {
    padding: 24px;
    max-width: 1500px;
    margin: 0 auto;

    p-message {
      display: block;
      margin-top: 16px;
    }
  }

  .p-toolbar {
    background: var(--body-background);
    padding: 9px 1rem;
  }

  .p-button-primary {
    .p-button {
      background: $primary-blue;
    }
  }

  .p-button-danger {
    margin-right: 10px;
  }

  .p-button-secondary {
    margin-right: 10px;

    .p-button {
      background: #64748B;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 500;
  }
}
