app-trial-edit-modal {
  .p-button-link {
    .p-button-label {
      color: var(--active-menu-item-text-color);
    }

    .p-button-icon {
      color: var(--active-menu-item-text-color);
    }
  }

  .editTrialModalForm {
    display: flex;
    flex-direction: column;

    p-button {
      align-self: flex-end;
    }
  }

  .modalSubHeaderBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
