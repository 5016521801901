.p-menubar-root-list > .p-element.p-menuitem.active {
  background: var(--active-menu-item-background-color);

  .p-menuitem-text {
    color: var(--active-menu-item-text-color);
  }

  .p-menuitem-icon {
    color: var(--active-menu-item-text-color);
  }
}
